import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Element as ScrollElement, Link as ScrollLink } from "react-scroll"
import ErrorIcon from "../assets/images/signup-error.svg"
// import ApplyNow from "../components/careers/apply-now"
// import AboutRoleTestimonial from "../components/careers/about-role-testimonial"
import Buttons from "../components/common/button"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "../styles/templates/career-single.scss"
function CareerSingle({ pageContext }) {
  const { career } = pageContext

  const [displayError, setDisplayError] = useState(false)
  const [success, setSuccess] = useState(false)

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "TICKET.subject",
      type: "text",
      label: "Job Title",
      errorMessage: "Please enter a job title",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "TICKET.content",
      type: "url",
      label: "Portfolio links",
      required: false,
      errorMessage: "Please enter a portfolio links",
    },
    {
      name: "cover_letter",
      type: "textarea",
      label: "Cover Letter",
      required: false,
      col: "10",
      errorMessage: "Please enter a valid message",
    },
    {
      name: "upload_file_link",
      type: "file",
      required: true,
      errorMessage: "Please upload your resume in .pdf and size < 10MB",
      acceptFiletype: "application/pdf",
    },
  ]
  const handleAfterSubmit = () => {
    setSuccess(true)
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentCareerSingle {
            SuperOps {
              pages(where: { title: "Careers" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
          }
        `}
        render={data => (
          <div className="career-single careers">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              return (
                <>
                  <Frame seo={career.seo} ogType="article" hubspotForm>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page={title}
                        color={career.heroBackgroundColor}
                      />
                    </header>

                    <section
                      className="hero"
                      style={{ background: career.heroBackgroundColor }}
                    >
                      <Container>
                        <Row>
                          <Col lg={7}>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <div className="breadcrumb p14">
                                <p>
                                  {" "}
                                  <a
                                    className="tdnone lowercase"
                                    href="/careers"
                                  >
                                    Careers
                                  </a>
                                  &nbsp;&nbsp;-&nbsp;&nbsp;
                                  <span className="lowercase">
                                    {career.tags[0].name}{" "}
                                  </span>
                                </p>
                              </div>
                            </Slide>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <h1> {career.jobTitle} </h1>
                            </Slide>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <ScrollLink
                                to="ApplyNow"
                                spy={true}
                                smooth={"easeInOutCubic"}
                                hashSpy={true}
                                offset={-100}
                                duration={300}
                                delay={0}
                                isDynamic={true}
                              >
                                <Buttons
                                  theme="primary lg"
                                  arrow
                                  text="APPLY NOW"
                                />
                              </ScrollLink>
                            </Slide>
                          </Col>
                          <Col lg={5} className="info-single">
                            <div className="grid-container">
                              <div className="grid-lg-2 grid-md-2 grid-sm-2">
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <div className="p14">
                                    <p> Seniority level </p>
                                  </div>

                                  <div className="p16">
                                    <p> {career.seniorityLevel} </p>
                                  </div>
                                </Slide>
                              </div>
                              <div className="grid-lg-2 grid-md-2 grid-sm-2">
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <div className="p14">
                                    <p> Location </p>
                                  </div>

                                  <div className="p16">
                                    <p> {career.location} </p>
                                  </div>
                                </Slide>
                              </div>
                              <div className="grid-lg-2 grid-md-2 grid-sm-2">
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <div className="p14">
                                    <p> Nature of job </p>
                                  </div>

                                  <div className="p16">
                                    <p> {career.natureOfJob} </p>
                                  </div>
                                </Slide>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="cover">
                      <Container>{/*  */}</Container>
                    </section>

                    <section className="content">
                      <Container>
                        <Row className="career-ab">
                          <Col lg={7} className="career-ab-content">
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <h4 className="fw-bold">
                                About your role at SuperOps
                              </h4>
                            </Slide>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <div className="about-role p16">
                                {parse(career.aboutRole.html)}
                              </div>
                            </Slide>
                          </Col>
                          <Col lg={5}>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <div className="testimonial">
                                <img
                                  src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/8EB6P96eSQ6QwJuxQPXt"
                                  alt="quote"
                                  height="10"
                                  width="10"
                                />
                                <div className="p18">
                                  <p>{career.peopleList[0].testimonial}</p>
                                </div>

                                <div className="guest">
                                  <div className="user">
                                    <img
                                      src={career.peopleList[0].thumbnail.url}
                                      alt="user"
                                      height="10"
                                      width="10"
                                    />
                                  </div>
                                  <div className="details">
                                    <span className="p16">
                                      <p className="fw-bold">
                                        {career.peopleList[0].name}
                                      </p>
                                    </span>
                                    <span className="p14">
                                      <p>{career.peopleList[0].title}</p>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Slide>
                          </Col>
                        </Row>
                      </Container>

                      <Container>
                        <div className="career-resp">
                          <Slide style={{ "--sal-delay": "0.2s" }}>
                            <h4 className="fw-bold">What You'll Do:</h4>
                          </Slide>
                          <Slide style={{ "--sal-delay": "0.2s" }}>
                            <div className="grid-container">
                              {career.responsibility.map(resp => {
                                return (
                                  <div className="grid-lg-2 grid-md-1 grid-sm-1">
                                    <div className="p16">
                                      <p>{resp}</p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Slide>
                        </div>
                      </Container>

                      <Container
                        style={{
                          background: career.experienceBackgroundColor,
                        }}
                        className="exp-container"
                      >
                        <div className="career-exp">
                          <Slide style={{ "--sal-delay": "0.2s" }}>
                            <h4 className="fw-bold"> Must Have:</h4>
                          </Slide>
                          <Slide style={{ "--sal-delay": "0.2s" }}>
                            <div className="grid-container">
                              {career.experience.map(exp => {
                                return (
                                  <div className="grid-lg-2 grid-md-1 grid-sm-1">
                                    <div className="p16">
                                      <p>{exp}</p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Slide>
                          {career.benefit?.length > 0 && (
                            <Slide className="mt50">
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <h4 className="fw-bold">
                                  Why You'll Love Working with us
                                </h4>
                              </Slide>
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <div className="grid-container">
                                  {career.benefit.map((benefit, index) => (
                                    <div
                                      key={index}
                                      className="grid-lg-2 grid-md-1 grid-sm-1"
                                    >
                                      <div className="p16">
                                        <p>{benefit}</p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Slide>
                            </Slide>
                          )}
                        </div>
                      </Container>
                    </section>

                    <ScrollElement name="ApplyNow">
                      <section className="apply career-apply">
                        <Container className="text-center">
                          {!success && !displayError && (
                            <h4 className="fw-bold"> Apply now </h4>
                          )}
                          <div className="d-flex justify-content-center">
                            <Col lg={8}>
                              <div
                                className="api-error p14"
                                style={{
                                  display: displayError ? "block" : "none",
                                }}
                              >
                                <img
                                  src={ErrorIcon}
                                  height="10"
                                  width="10"
                                  className="position-relative"
                                  alt="img"
                                />
                                <p className="text-start mt16">
                                  Oops, we've hit a glitch. Try entering the
                                  details again.
                                </p>
                              </div>
                              {!success ? (
                                <FormBuilder
                                  formClassName="forms"
                                  errorClassName="error-message"
                                  inputErrorClassName="error-active"
                                  data={formdata}
                                  buttonText={"SUBMIT"}
                                  buttonClassName="primary"
                                  // IpStackData
                                  endpoint={
                                    process.env.HUBSPOT_CAREERS_API_ENDPOINT
                                  }
                                  url={process.env.HUBSPOT_CAREERS_API_URL}
                                  formName={`${career.jobTitle} - Superops`}
                                  afterSubmit={handleAfterSubmit}
                                  errorHandler={(d, res) => {
                                    setDisplayError(true)
                                  }}
                                  fileUpload
                                  roleApplied={career.jobTitle}
                                />
                              ) : (
                                <>
                                  <div className="text-center mb24">
                                    <SVGIcon name="upload-file-success" />

                                    <h3 className="mb24 fw-bold">Done!</h3>

                                    <div className="mb50 text-grey">
                                      <p className="p16 ">
                                        {" "}
                                        Thank you for taking the time to apply.
                                        We’ll take a look at your profile and
                                        get in touch when have an opportunity
                                        that fits you!{" "}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Col>
                          </div>
                        </Container>
                      </section>
                    </ScrollElement>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default CareerSingle
